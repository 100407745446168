input[type="date"] {
  position: relative;
  padding: 5.5px 11px;
  font-size: 16px;
  line-height: 24px;
  -webkit-appearance: none;
  color: black;
  background-color: white;
  border: solid 1px black;
  border-radius: 0;
  width: unset;
}

input[type="date"]::-ms-expand {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

input[type="date"]::-webkit-date-and-time-value {
  text-align: left
}

input[type="date"]:before {
  display: block;
  content: '';
  background: url('../../assets/images/icon-calendar-outline.png') no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 16px;
}

input[type="date"]:focus {
  outline: solid 0px #333333;
}

div[data-testid="RadioButton"]>div {
  margin: 0 !important;
}

div[data-testid="RadioButton"] {
  width: 20px;
  height: 20px;
}

div[data-testid="Checkbox"] {
  padding: 0 !important;
  width: 24px;
  height: 24px;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #E6E6E6;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background: #CCCCCC;
}